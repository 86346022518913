/* ===============================================================
Youtube player Styles 
================================================================*/
.w-yt-player,
.ui-yt-player,
#video-popup-container {
    position: relative;
    /* z-index: 1; */
    overflow: hidden;
    user-select: none;    
}

.ytp-controls-bar {
    position: absolute;
    left: 0;
    height: 75px;
    width: 100%;
    color: var(--common-white);
    bottom: 5px;
    background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.5) 25%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0.1) 75%, rgba(0,0,0,0.0) 100%);
    display: none;
    z-index: 2147483647;
}

.w-ytp-controls {
    position: absolute;
    bottom: 5px;
    left: 10%;
    height: 50px;
    width: 80%;
    user-select: none;    
}

.ytp-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: left;
    cursor: default;
}

.yt-button {
    cursor: pointer;
}

.ytp-controls .ytp-button,
.ytp-controls .ytp-fullscreen-button,
.ytp-controls .ytp-exitfullscreen-button {
    padding: 0;
    font-size: 2.4rem;
    line-height: 1;
    background: transparent;
    color: var(--common-white);
    border: none;
}

.ytp-left-controls {
    float: left;
    height: 100%;
}

.ytp-right-controls {
    float: right;
    height: 100%;
}

/* left side controls*/
.ytp-left-controls .ytp-button {
    padding-right: 10px;
}

.ytp-time-display {
    pointer-events: none;
    margin-left: 5px;
}

.ytp-time-display .ytp-time-divider {
    margin: 0 3px;
}

.ytp-time-display .ytp-time-current,
.ytp-time-display .ytp-time-duration {
    display: inline-block;
    font-size: var(--font-size-caption);
    line-height: 2;
    vertical-align: middle;
}

.ytp-time-display .ytp-time-separator {
    width: 3px;
    margin-left: -3px;
}

/* right side controls*/
.ytp-right-controls .ytp-button {
    padding-left: 10px;
}

/* progress bar*/

.ytp-progress-bar {
    position: absolute;
    left: 0;
    height: 4px;
    background: #ccccca;
    cursor: pointer;
    margin: 0;
    width: 100%;
    z-index: 10;
    transition: left .1s cubic-bezier(0.4,0.0,1,1),padding .1s cubic-bezier(0.4,0.0,1,1),width .5s cubic-bezier(0.4,0.0,1,1);
}

.ytp-play-progress {
    background: #b4d718;
    width: 0;
    height: 4px;
    transition: left .1s cubic-bezier(0.0,0.0,0.2,1),padding .1s cubic-bezier(0.0,0.0,0.2,1),width .1s cubic-bezier(0.0,0.0,0.2,1);
}

.ytp-play-progress .ytp-play-elips {
    position: absolute;
    top: -5px;
    width: 14px;
    height: 14px;
    background: var(--common-white);
    border-radius: 50%;
    transition: left .1s cubic-bezier(0.0,0.0,0.2,1),padding .1s cubic-bezier(0.0,0.0,0.2,1),width .1s cubic-bezier(0.0,0.0,0.2,1);
}

/* Volume control*/
.ytp-volume-control {
    display: inline-block;
}

.ytp-volume-panel {
    display: inline-block;
    width: 0;
    height: 30px;
    transition: margin .2s cubic-bezier(0.4,0.0,1,1),padding .2s cubic-bezier(0.4,0.0,1,1),width .5s cubic-bezier(0.4,0.0,1,1);
    cursor: pointer;
}

.ytp-volume-control:hover .ytp-volume-panel {
     width: 52px;
}

.ytp-volume-slider-active .ytp-volume-panel {
    width: 52px;
    transition: margin .2s cubic-bezier(0.0,0.0,0.2,1),padding .2s cubic-bezier(0.0,0.0,0.2,1),width .2s cubic-bezier(0.0,0.0,0.2,1);
}

.ytp-keyboard-focus .ytp-volume-panel:focus {
    box-shadow: inset 0 0 0 2px rgba(27,127,204,.8);
}

.ytp-volume-slider {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.ytp-volume-slider-handle {
    position: absolute;
    top: 50%;
    width: 4px;
    height: 13px;
    margin-top: 3.5px;
    margin-left: -2px;
    background: var(--common-white)
}

.ytp-volume-slider-handle::before,.ytp-volume-slider-handle::after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    height: 3px;
    margin-top: -1.5px;
    width: 52px;
}

.ytp-volume-slider-handle::before {
    left: -52px;
    background: #b4d718;
}

.ytp-volume-slider-handle::after {
    left: 4px;
    background: rgba(255,255,255,.2);
}

.ytp-color-white .ytp-volume-slider-handle::before {
    background: var(--grey-a200);
}

.ytp-color-blue .ytp-volume-slider-handle::before {
    background: #3793e6;
}

.ytp-color-party .ytp-volume-slider-handle::before {
    animation: ytp-party-background-color .1s linear infinite;
}

/* Quality popup */
.ytp-settings-menu {
    display: none;
    position: absolute;
    bottom: 52px;
    padding: 3px 0;
    width: 85px;
    font-size: var(--font-size-caption);
    z-index: 5000;
    border-radius: 4px;
    background: rgba(0,0,0,0.6);
}
.ytp-settings-menu.visible {
    display: block;
}
.ytp-menuitems .quality-items {
    position: relative;
    text-align: left;
    list-style-type: none;
    padding-left: 20px;
    line-height: 22px;
    height: 22px;
    color: var(--common-white);
    cursor: pointer;
}

.ytp-menuitems .quality-items:hover::before,
.ytp-menuitems .quality-items.active::before {
    position: absolute;
    content: '';
    left: 8px;
    top: 50%;
    margin-top: -3px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #b4d718;
    color: var(--common-white);
}
/* Full screen */
.ytp-exitfullscreen-button {
    display: none;
}

/* Brand bampaign video*/
.ui-video-preview.ui-yt-player .ytp-controls-bar{
    bottom: 18px;
}

/* Productlisting video banner*/
#youtube-player-container .ytp-controls-bar,
.video-slide .ytp-controls-bar  {
    bottom: 0;
}

/*   Breapoint - size 2 + 3 */
@media only screen and (max-width: 768px) {
    .ytp-button,
    .ytp-volume-slider,
    .ytp-progress-bar {
        cursor: default;
    }
}

@media only screen and (max-width: 468px) {
    .ytp-controls-bar {
        height: 50px;
    }
    .w-ytp-controls {
        height: 35px;
    }
    .ytp-controls {
        height: 35px;
        line-height: 35px;
    }
    .ytp-left-controls .ytp-button {
        padding-bottom: 10px;
    }
    .ytp-time-display .ytp-time-current, 
    .ytp-time-display .ytp-time-duration {
        margin-bottom: 10px;
    }
    .ytp-volume-slider-handle {
        margin-top: 0;
    }
    .ytp-time-display .ytp-time-divider {
        margin-bottom: 10px;
    }
}